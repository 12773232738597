@import "./variables";

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin img-center {
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin slider-type-icon {
  position: relative;

  &::before, &::after {
    content: "";
    position: absolute;
    left: 1px;
    bottom: 0;
    width: 123px;
    height: 8px;
    background: $yellow-color;
    @media (max-width: 959px) {
      width: 53px;
      height: 4px;
    }
    @media (max-width: 599px) {
      width: 47px;
    }
  }

  &::after {
    width: 8px;
    height: 145px;
    @media (max-width: 959px) {
      width: 4px;
      height: 63px;
    }
    @media (max-width: 599px) {
      height: 56px;
    }
  }
}

@mixin blogs-type-icon {
  position: relative;

  &::before, &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 123px;
    height: 8px;
    background: $yellow-color;
    @media (max-width: 1099px) {
      width: 54px;
      height: 4px;
    }
    @media (max-width: 599px) {
      width: 36px;
      height: 2px;
    }
  }

  &::after {
    width: 8px;
    height: 170px;
    @media (max-width: 1099px) {
      width: 4px;
      height: 74px;
    }
    @media (max-width: 599px) {
      width: 2px;
      height: 49px;
    }
  }
}

@mixin filters-type-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 51px;
  height: 40px;
  @media (max-width: 959px) {
    width: 37px;
    height: 27px;
  }
  @media (max-width: 599px) {
    width: 28px;
    height: 20px;
  }

  &::before, &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 51px;
    height: 3px;
    background: $yellow-color;
    @media (max-width: 959px) {
      width: 37px;
      height: 2px;
    }
    @media (max-width: 599px) {
      width: 28px;
    }
  }

  &::after {
    width: 3px;
    height: 37px;
    @media (max-width: 959px) {
      width: 2px;
      height: 27px;
    }
    @media (max-width: 599px) {
      height: 20px;
    }
  }
}

@mixin filter-button {
  width: 177px;
  height: 40px;
  padding: 0 5px;
  border: none;
  font-family: HelveticaNeue, sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 900;
  color: #fff;
  outline: none;
  text-transform: uppercase;
  @media (max-width: 959px) {
    width: 130px;
    height: 30px;
    font-size: 12px;
    line-height: 15px;
  }
  @media (max-width: 599px) {
    width: 90px;
    height: 20px;
    font-size: 10px;
    line-height: 12px;
  }
}

@mixin reset-button-styles {
  padding: 0;
  background: none;
  border: none;
  outline: none;
}