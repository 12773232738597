@import "../../../styles/mixins";
@import "../../../styles/variables";

.filtersContainer {
  display: flex;
  margin-bottom: 75px;
  @media (max-width: 959px) {
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.filters {
  position: relative;
  min-width: 550px;
  height: 173px;
  margin-left: 243px;
  @media (max-width: 1299px) {
    margin-left: 90px;
  }
  @media (max-width: 959px) {
    min-width: auto;
    width: 410px;
    height: 127px;
    margin: 0 0 50px 130px;
  }
  @media (max-width: 599px) {
    width: 290px;
    height: 100px;
    margin: 0 auto 40px;
  }
}

.filtersButton {
  @include filter-button;
}

.filtersButtonYellow {
  position: absolute;
  top: 0;
  left: 0;
  background: $yellow-color;
}

.filtersButtonPurple {
  position: absolute;
  bottom: 0;
  right: 0;
  background: $purple-color;
}

.filtersButtonBlue {
  position: absolute;
  bottom: 0;
  left: 0;
  background: $blue-color;
}

.filtersResetContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 173px;
  height: 100%;
  @media (max-width: 959px) {
    width: 130px;
  }
  @media (max-width: 599px) {
    width: 100px;
  }
}

.filtersIconForYou {
  @include filters-type-icon;
}

.filtersIconDigital {
  @include filters-type-icon;

  top: auto;
  bottom: 0;

  &::before, &::after {
    background: $blue-color;
  }

  &::before {
    top: auto;
    left: 0;
    bottom: 0;
  }
}

.filtersIconInnovativ {
  @include filters-type-icon;

  left: auto;
  right: 0;
  top: auto;
  bottom: 0;

  &::before, &::after {
    background: $purple-color;
  }

  &::before {
    top: auto;
    bottom: 0;
  }

  &::after {
    left: auto;
    right: 0;
  }
}

.filtersResetButton {
  @include reset-button-styles;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background: $main-color;
  @media (max-width: 959px) {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 599px) {
    width: 20px;
    height: 20px;
  }
}

.filtersOpacity {
  opacity: 0.2;
}

.filtersTextContainer {
  align-self: flex-end;
  margin: 0 10px 0 70px;
  @media (max-width: 1299px) {
    margin: 0 10px 0 20px;
  }
  @media (max-width: 959px) {
    align-self: flex-start;
    margin: 0 10px 0 80px;
    padding: 0 5px;
  }
  @media (max-width: 599px) {
    margin: 0 auto;
    padding: 0 5px;
    text-align: center;
  }
}

.filtersText {
  font-size: 18px;
  line-height: 22px;
  text-align: justify;
  @media (max-width: 959px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (max-width: 599px) {
    font-size: 12px;
    line-height: 15px;
  }
}
