@import "../../../styles//variables";
@import "../../../styles/mixins";

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 140px 0 223px;
  background: #fff;
  @media (max-width: 959px) {
    padding: 0 80px 0 65px;
  }
  @media (max-width: 599px) {
    padding: 0 37px 0 27px;
  }
}

.logo {
  display: block;
  height: 150px;
  margin: 52px 0 67px;
  @media (max-width: 959px) {
    height: 110px;
    margin-top: 39px;
  }
  @media (max-width: 599px) {
    height: 78px;
    margin-top: 34px;
  }
}

.home {
  @include flex-center;
  width: 46px;
  height: 67px;
  background: #2B2B2A;
  cursor: pointer;
  @media (max-width: 959px) {
    width: 37px;
    height: 53px;
  }
  @media (max-width: 599px) {
    width: 27px;
    height: 38px;
  }
}

.homeIcon {
  @media (max-width: 959px) {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 599px) {
    width: 18px;
    height: 18px;
  }
}

.unlockIcon {
  width: 22px;
  height: 29px;
  @media (max-width: 959px) {
    width: 18px;
    height: 24px;
  }
  @media (max-width: 599px) {
    width: 14px;
    height: 18px;
  }
}
