@import "../../styles/mixins";

.intro {
  position: relative;
  height: 562px;
  margin-bottom: 145px;
  @media (max-width: 959px) {
    height: 245px;
    margin-bottom: 63px;
  }
  @media (max-width: 599px) {
    height: 375px;
    margin-bottom: 95px;
  }
}

.introOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #D60B52;
  cursor: pointer;
}

.introButtonDown {
  @include reset-button-styles;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  padding: 18px;
}