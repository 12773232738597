@import "../../../styles/variables";

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 184px;
  padding: 0 243px;
  border-top: 10px solid $light-gray-color;
  box-sizing: border-box;
  @media (max-width: 1599px) {
    padding: 0 140px;
  }
  @media (max-width: 1199px) {
    padding: 0 70px;
  }
  @media (max-width: 959px) {
    height: 130px;
    padding: 0 15px;
  }
  @media (max-width: 649px) {
    position: relative;
    height: 340px;
    padding: 0 12px;
  }
}

.footerControls {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.logo {
  width: 290px;
  margin-top: 10px;
  @media (max-width: 959px) {
    width: 180px;
    height: 57px;
  }
  @media (max-width: 649px) {
    width: 165px;
    height: 75px;
    margin-top: 170px;
  }
}

.socialList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 210px;
  @media (max-width: 1599px) {
    width: 150px;
    margin: 0 15px;
  }
  @media (max-width: 649px) {
    position: absolute;
    top: 57px;
    left: 50%;
    transform: translateX(-50%);
    width: 138px;
  }
}

.socialNetworks {
  display: flex;
  text-decoration: none;
}

.socialLink {
  font-size: 22px;
  color: #393737;
  transition: all 0.6s;
}

.socialLink:hover {
  fill: #D5D5D5;
}

.navList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 480px;
  list-style: none;
  @media (max-width: 1599px) {
    width: 400px;
  }
  @media (max-width: 649px) {
    flex-direction: column;
    align-items: flex-end;
    width: auto;
    height: 85px;
    margin-top: 170px;
  }
}

.navItem:not(:last-child) {
  margin-right: 10px;
  @media (max-width: 649px) {
    margin-right: 0;
  }
}

.navLink {
  color: $main-text-color;
  font-size: 17px;
  line-height: 21px;
  text-decoration: none;
  font-family: HelveticaNeue, Arial, Helvetica, sans-serif;
  transition: all 0.2s;
  @media (max-width: 959px) {
    font-size: 14px;
    line-height: 17px;
  }
}

.navLink:hover {
  color: #D5D5D5;
}

.footerText {
  padding-bottom: 20px;
  @media (max-width: 959px) {
    padding-bottom: 10px;
  }
  @media (max-width: 649px) {
    padding-top: 10px;
  }
}

.footerLocation {
  @media (max-width: 959px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (max-width: 649px) {
    text-align: center;
  }
}

.footerLocationLink {
  color: $main-color;
  transition: 0.3s;
  
  &:hover {
    opacity: 0.6;
  }
}