@import "../../styles//variables";
@import "../../styles//mixins";

.slider {
  margin-bottom: 255px;
  @media (max-width: 959px) {
    margin-bottom: 78px;
  }
  @media (max-width: 599px) {
    margin-bottom: 85px;
  }
}

.sliderHeader {
  padding: 93px 0 93px 247px;
  background: $gray-color;
  @media (max-width: 1099px) {
    padding: 93px 0 25px 170px;
  }
  @media (max-width: 959px) {
    padding: 25px 0 12px 68px;
  }
  @media (max-width: 599px) {
    padding: 20px 15px 10px;
  }
}

.sliderTitle {
  padding-bottom: 25px;
  @media (max-width: 959px) {
    padding-bottom: 20px;
  }
  @media (max-width: 599px) {
    padding-bottom: 10px;
  }
}

.sliderText {
  width: 738px;
  text-align: justify;
  line-height: 22px !important;
  @media (max-width: 959px) {
    width: 490px;
  }
  @media (max-width: 599px) {
    width: 100%;
    box-sizing: border-box;
    text-align: justify;
  }
}

.sliderWrapper {
  position: relative;
  display: flex !important;
  cursor: pointer;
}

.sliderIconSie {
  @include slider-type-icon;
}

.sliderIconDigital {
  @include slider-type-icon;

  &::before, &::after {
    background: $blue-color;
  }
}

.sliderIconInnovativ {
  @include slider-type-icon;

  &::before, &::after {
    background: $purple-color;
  }
}

.sliderIconCentral {
  @include slider-type-icon;

  &::before, &::after {
    background: $main-color;
  }
}

.sliderImage {
  width: 100%;
  border-left: 1px solid #fff;
  height: 481px;
  object-fit: cover;
  @media (max-width: 959px) {
    height: 209px;
  }
  @media (max-width: 599px) {
    height: 187px;
  }
}

.overlay-FOR_YOU,
.overlay-DIGITAL,
.overlay-INNOVATIVE,
.overlay-CENTRAL {
  @include flex-center;
  flex-direction: column;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 1px;
  right: 0;
  bottom: 0;
  transition: all ease-in-out 0.2s;
  z-index: 1;
}

.sliderIconSie:hover .overlay-FOR_YOU {
  background: $yellow-color;
  visibility: visible
}

.sliderIconDigital:hover .overlay-DIGITAL {
  background: $blue-color;
  visibility: visible
}

.sliderIconInnovativ:hover .overlay-INNOVATIVE {
  background: $purple-color;
  visibility: visible
}

.sliderIconCentral:hover .overlay-CENTRAL {
  background: $main-color;
  visibility: visible
}

.overlayText {
  visibility: hidden;
  display: -webkit-box;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 100%;
  padding: 0 10px;
  font-size: 20px !important;
  line-height: 25px !important;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  @media (max-width: 1099px) {
    font-size: 14px !important;
    line-height: 16px !important;
  };
  @media (max-width: 599px) {
    font-size: 12px !important;
    line-height: 14px !important;
  }
}

.overlayText:first-child {
  font-weight: 700;
}

.overlayText:not(:last-child) {
  margin-bottom: 10px;
}

.overlay-FOR_YOU:hover .overlayText,
.overlay-DIGITAL:hover .overlayText,
.overlay-INNOVATIVE:hover .overlayText,
.overlay-CENTRAL:hover .overlayText {
  visibility: visible;
}
