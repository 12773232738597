@import "./styles/reset";
@import "./styles/variables";
@import "./styles/mixins";

body {
  font-family: HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1.ce-header {
  font-size: 1.7em;
}

.container {
  margin: 0 auto;
  max-width: 1920px;
  min-width: 320px;
  box-sizing: border-box;
}

.container_admin {
  margin: 0 auto;
  max-width: 1920px;
  min-width: 860px;
  box-sizing: border-box;
}

.preloadingSection {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.visibility_hidden {
  visibility: hidden;
}

.slick-arrow {
  @media (max-width: 959px) {
    height: 28px !important;
  }
}