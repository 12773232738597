@import "../../styles/mixins";
@import "../../styles/variables";

.blogs {
  margin-bottom: 300px;
  @media (max-width: 959px) {
    margin-bottom: 90px;
  }
  @media (max-width: 599px) {
    margin-bottom: 50px;
  }
}

.addBlogs {
  @include flex-center;
  height: 38px;
  margin-top: 100px;
  @media (max-width: 599px) {
    margin-top: 50px;
  }
}