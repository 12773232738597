@import "../../styles/mixins";
@import "../../styles/variables";

.item-FOR_YOU,
.item-DIGITAL,
.item-INNOVATIVE {
  @include blogs-type-icon;
  cursor: pointer;
}

.item-FOR_YOU {
  &::before, &::after {
    background: $yellow-color;
  }
}

.item-DIGITAL {
  &::before, &::after {
    background: $blue-color;
  }
}

.item-INNOVATIVE {
  &::before, &::after {
    background: $purple-color;
  }
}

.overlay-FOR_YOU,
.overlay-DIGITAL,
.overlay-INNOVATIVE {
  @include flex-center;
  visibility: hidden;
  position: relative;
  height: 100%;
  transition: all ease-in-out 0.2s;
  z-index: 1;
}

.item-FOR_YOU:hover .overlay-FOR_YOU {
  background: $yellow-color;
  visibility: visible
}

.item-DIGITAL:hover .overlay-DIGITAL {
  background: $blue-color;
  visibility: visible
}

.item-INNOVATIVE:hover .overlay-INNOVATIVE {
  background: $purple-color;
  visibility: visible
}

.overlayContent {
  visibility: hidden;
}

.overlayTitle,
.overlayText,
.overlayType {
  // display: -webkit-box;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 6;
  width: 100%;
  padding: 0 10px;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  word-break: break-word;
  max-height: 200px;
  @media (max-width: 1099px) {
    font-size: 14px;
    line-height: 16px;
    max-height: 64px;
    // -webkit-line-clamp: 4;
  };
  @media (max-width: 599px) {
    font-size: 12px;
    line-height: 14px;
    max-height: 56px;
  };
}

.overlayType {
  margin-bottom: 5px;
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 1099px) {
    margin-bottom: 0;
  };
}

.overlayTitle {
  max-height: 100px;
  margin-bottom: 10px;
  font-weight: 700;
  @media (max-width: 1099px) {
    // -webkit-line-clamp: 3;
    margin-bottom: 5px;
    max-height: 48px;
  };
  @media (max-width: 599px) {
    // -webkit-line-clamp: 2;
    max-height: 28px;
  };
}

.item-FOR_YOU:hover .overlayContent,
.item-DIGITAL:hover .overlayContent,
.item-INNOVATIVE:hover .overlayContent {
  visibility: visible;
}

.item-one {
  grid-area: one;
  @include img-center;
  text-decoration: none;
}

.item-two {
  grid-area: two;
  @include img-center;
  text-decoration: none;
}

.item-three {
  grid-area: three;
  @include img-center;
  text-decoration: none;
}

.item-four {
  grid-area: four;
  @include img-center;
  text-decoration: none;
}

.item-five {
  grid-area: five;
  @include img-center;
  text-decoration: none;
}

.item-six {
  grid-area: six;
  @include img-center;
  text-decoration: none;
}

.item-seven {
  grid-area: seven;
  @include img-center;
  text-decoration: none;
}

.item-eight {
  grid-area: eight;
  @include img-center;
  text-decoration: none;
}

.item-nine {
  grid-area: nine;
  @include img-center;
  text-decoration: none;
}

.item-ten {
  grid-area: ten;
  @include img-center;
  text-decoration: none;
}

.blogsList {
  position: relative;
  min-height: 370px;
}

.blogsListOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $gray-color;
  opacity: 0.5;
  z-index: 19;
}

.blogsBlock {
  display: grid;
  grid-auto-rows: 120px;
  gap: 1px;
  margin-bottom: 1px;
  @media (max-width: 1099px) {
    grid-auto-rows: 52px;
  };
  @media (max-width: 599px) {
    grid-auto-rows: 36px;
  }
}

.blogsBlockOne {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
  "one"
  "one"
  "one";
  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "one ."
    "one ."
    "one ."
    "one .";
  }
}

.blogsBlockTwo {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
  "one two"
  "one two"
  "one two";
  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "one two"
    "one two"
    "one two"
    "one two";
  }
}

.blogsBlockThree {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
  "one   two   three"
  "one   two   three"
  "one   two   three"
  ".     .     three"
  ".     .     three";
  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "one   two"
    "one   two"
    "one   two"
    "one   two"
    "three  ."
    "three  ."
    "three  ."
    "three  .";
  }
}

.blogsBlockFour {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
  "one  two three"
  "one  two three"
  "one  two three"
  "four .   three"
  "four .   three"
  "four .   ."
  "four .   .";
  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "one   two"
    "one   two"
    "one   two"
    "one   two"
    "three four"
    "three four"
    "three four"
    "three .";
  }
}

.blogsBlockFive {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
  "one  two  three"
  "one  two  three"
  "one  two  three"
  "four five three"
  "four five three"
  "four five ."
  "four .    .";
  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "one   two"
    "one   two"
    "one   two"
    "one   two"
    "three four"
    "three four"
    "three four"
    "three ."
    "five  ."
    "five  ."
    "five  ."
    "five  ."
    "five  .";
  }
}

.blogsBlockSix {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
  "one  two  three"
  "one  two  three"
  "one  two  three"
  "four five three"
  "four five three"
  "four five ."
  "four six  ."
  ".    six  ."
  ".    six  .";
  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "one   two"
    "one   two"
    "one   two"
    "one   two"
    "three four"
    "three four"
    "three four"
    "three six"
    "five  six"
    "five  six"
    "five  ."
    "five  ."
    "five  .";
  }
}

.blogsBlockSeven {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
  "one  two  three"
  "one  two  three"
  "one  two  three"
  "four five three"
  "four five three"
  "four five seven"
  "four six  seven"
  ".    six  seven"
  ".    six  .";
  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "one   two"
    "one   two"
    "one   two"
    "one   two"
    "three four"
    "three four"
    "three four"
    "three six"
    "five  six"
    "five  six"
    "five  seven"
    "five  seven"
    "five  seven";
  }
}

.blogsBlockEight {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
  "one   two  three"
  "one   two  three"
  "one   two  three"
  "four  five three"
  "four  five three"
  "four  five seven"
  "four  six  seven"
  "eight six  seven"
  "eight six  ."
  "eight .    ."
  "eight .    ."
  "eight .    .";
  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "one   two"
    "one   two"
    "one   two"
    "one   two"
    "three four"
    "three four"
    "three four"
    "three six"
    "five  six"
    "five  six"
    "five  seven"
    "five  seven"
    "five  seven"
    "eight ."
    "eight ."
    "eight ."
    "eight ."
    "eight ."
    "eight ."
    "eight .";
  }
}

.blogsBlockNine {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
  "one   two  three"
  "one   two  three"
  "one   two  three"
  "four  five three"
  "four  five three"
  "four  five seven"
  "four  six  seven"
  "eight six  seven"
  "eight six  ."
  "eight nine ."
  "eight nine ."
  "eight nine .";
  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "one   two"
    "one   two"
    "one   two"
    "one   two"
    "three four"
    "three four"
    "three four"
    "three six"
    "five  six"
    "five  six"
    "five  seven"
    "five  seven"
    "five  seven"
    "eight nine"
    "eight nine"
    "eight nine"
    "eight ."
    "eight ."
    "eight ."
    "eight .";
  }
}


.blogsBlockTen {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
  "one   two  three"
  "one   two  three"
  "one   two  three"
  "four  five three"
  "four  five three"
  "four  five seven"
  "four  six  seven"
  "eight six  seven"
  "eight six  ten"
  "eight nine ten"
  "eight nine ten"
  "eight nine ten";
  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "one   two"
    "one   two"
    "one   two"
    "one   two"
    "three four"
    "three four"
    "three four"
    "three six"
    "five  six"
    "five  six"
    "five  seven"
    "five  seven"
    "five  seven"
    "eight nine"
    "eight nine"
    "eight nine"
    "eight ten"
    "eight ten"
    "eight ten"
    "eight ten";
  }
}
